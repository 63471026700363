var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.i18n)?_c('div',{staticClass:"boxes"},[(_vm.hasData.index)?_c('my-box',{staticClass:"boxes__item",attrs:{"content":{
      title: _vm.i18n.meterInfo.index.title,
    }},scopedSlots:_vm._u([{key:"content",fn:function(){return _vm._l((_vm.indexData),function(data,meter){return _c('div',{key:meter},[_c('span',{staticClass:"headline mb-xxxs"},[_vm._v(" "+_vm._s(_vm.i18n.meterInfo.index.subtitle)+" - "+_vm._s(_vm.dayjs(data[0].dateRel).format('DD.MM.YYYY'))+" ")]),_vm._l((data[0].compteur),function(cpt,numCpt){return _c('div',{key:numCpt},[_c('div',{staticClass:"tiny"},[_vm._v(" "+_vm._s(_vm.convertRegister( cpt.catCadranLibelle || _vm.cadran(cpt.cadran).catCadranLibelle || _vm.cadran(cpt.cadran).cadran ))+": ")]),_c('span',{staticClass:"number"},[_vm._v(" "+_vm._s(_vm.trimzerosIndex(cpt.index || _vm.cadran(cpt.cadran).index))+" "+_vm._s(_vm.convertUnit(cpt.indexUnit || _vm.cadran(cpt.cadran).indexUnit))+" ")])])})],2)})},proxy:true},{key:"footer",fn:function(){return [_c('CustomLink',{attrs:{"content":{
          to: { name: 'MyMeterHistory', params: { id: _vm.id } },
          label: _vm.i18n.meterInfo.index.viewAll,
          icon: {
            size: '0 0 24 24',
            name: 'arrows-large-right',
          },
        },"modifiers":['dark', 'link', 'icon', 'arrow']}})]},proxy:true}],null,false,405128966)}):_vm._e(),(_vm.hasData.billing)?_c('my-box',{attrs:{"content":{
      title: _vm.i18n.meterInfo.paiements.title,
    }},scopedSlots:_vm._u([(_vm.billingData.solde)?{key:"content",fn:function(){return [_c('span',{staticClass:"headline mb-xxxs"},[_vm._v(" "+_vm._s(_vm.billingData.textSolde.replace(':', '') || _vm.i18n.meterInfo.paiements.subtitle)+" ")]),_c('span',{staticClass:"number"},[_vm._v(_vm._s(_vm.billingData.solde)+" "+_vm._s(_vm.billingData.devise))])]},proxy:true}:null,{key:"footer",fn:function(){return [_c('CustomLink',{attrs:{"content":{
          to: { name: 'MyMeterInvoices', params: { id: _vm.id } },
          label: _vm.i18n.meterInfo.paiements.viewAll,
          icon: {
            size: '0 0 24 24',
            name: 'arrows-large-right',
          },
        },"modifiers":['dark', 'link', 'icon', 'arrow']}})]},proxy:true}],null,true)}):_vm._e(),(_vm.hasData.meter && _vm.meterData.compteurs)?_c('my-box',{attrs:{"content":{ title: _vm.i18n.meterInfo.mymeter.title }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.meterData.statutEan)?_c('div',{staticClass:"tiny"},[_vm._v(" "+_vm._s(_vm.i18n.meterInfo.mymeter.status)+": "+_vm._s(_vm.meterData.statutEan)+" ")]):_vm._e(),(_vm.meterData.uCodeLibelle)?_c('div',{staticClass:"tiny"},[_vm._v(" "+_vm._s(_vm.i18n.meterInfo.mymeter.plan)+": "+_vm._s(_vm.meterData.uCodeLibelle)+" ")]):_vm._e(),(_vm.meterData.nbPhases)?_c('div',{staticClass:"tiny"},[_vm._v(" "+_vm._s(_vm.i18n.meterInfo.mymeter.phasesNb)+": "+_vm._s(_vm.meterData.nbPhases === '2' || _vm.meterData.nbPhases === '1N' ? _vm.i18n.singlePhase : _vm.i18n.threePhase)+" ")]):_vm._e(),(_vm.meterData.pContPrel)?_c('div',[_c('div',{staticClass:"tiny"},[_vm._v(_vm._s(_vm.i18n.meterInfo.mymeter.powerPrel)+" :")]),_c('div',{staticClass:"number box__amount"},[_vm._v(_vm._s(_vm.meterData.pContPrel)+" kVA")])]):_vm._e(),(_vm.meterData.pTotProd && _vm.meterData.pTotProd !== '0.0')?_c('div',[_c('div',{staticClass:"tiny"},[_vm._v(_vm._s(_vm.i18n.meterInfo.mymeter.powerProd)+" :")]),_c('div',{staticClass:"number box__amount"},[_vm._v(_vm._s(_vm.meterData.pTotProd)+" kVA")])]):_vm._e(),(_vm.meterData.reglPrel && _vm.meterData.reglPrel !== 0)?_c('div',[_c('div',{staticClass:"tiny"},[_vm._v(_vm._s(_vm.i18n.meterInfo.mymeter.amperage)+" :")]),_c('div',{staticClass:"number box__amount"},[_vm._v(" "+_vm._s(_vm.trimzerosIndex(_vm.meterData.reglPrel))+" A ")])]):_vm._e()]},proxy:true},{key:"footer",fn:function(){return [_c('CustomLink',{attrs:{"content":{
          to: {
            name: 'MyMeterInfo',
            params: { meter: _vm.meterData, id: _vm.id },
          },
          icon: {
            size: '0 0 24 24',
            name: 'arrows-large-right',
          },
          label: _vm.i18n.meterInfo.mymeter.viewAll,
        },"modifiers":['dark', 'link', 'icon', 'arrow']}})]},proxy:true}],null,false,2688082084)}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }