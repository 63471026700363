






































































































































import { defineComponent } from '@vue/composition-api'
import { useState, useGetters } from '@u3u/vue-hooks'
import dayjs from 'dayjs'

import MyBox from '@/components/my/Box.vue'
import EanInfo from '@/components/my/EanInfo.vue'
import CustomLink from '@/components/g/CustomLink.vue'

import { Ean } from '@/inc/types'
import {
  cadran,
  convertRegister,
  convertUnit,
  trimzerosIndex,
} from '@/inc/utils'

export default defineComponent({
  name: 'my-ean-dashboard',
  components: { MyBox, EanInfo, CustomLink },
  props: {
    ean: {
      type: Object as () => Ean,
      required: true,
    },
    indexData: {
      type: Object,
      required: true,
    },
    billingData: {
      type: Object,
      required: true,
    },
    invoiceData: {
      type: Object,
      required: true,
    },
    meterData: {
      type: Object,
      required: true,
    },
  },
  setup(props, ctx) {
    const { $route } = ctx.root
    const { id } = $route.params
    const { i18n } = useState('my', ['i18n'])
    const { hasData } = useGetters('my', ['hasData'])

    return {
      dayjs,
      cadran,
      convertUnit,
      convertRegister,
      trimzerosIndex,
      id,
      hasData,
      i18n,
    }
  },
})
